@import "custom";

/*
body {
  background-size: contain; 
  background-image: url("./wordcloud.svg");
  background-position: top;
  background-blend-mode: color;
  background-repeat: space;
}*/

.navItemWithIcon {
  align-items: center;
  border: 1px solid #bfbfbf;
  padding-left: 5px;
  margin-left: 2px;
  margin-top: 2px;

  a svg {
    width: 16px;
    margin-right: 0.45rem;
  }
}

.skillHeader {
  svg {
    color: red;
    /* background-color: gray; */
    /* margin-right: 3px; */
    margin-right: 5px;
    padding: 4px;
    width: 36px;
    height: 36px;
  }
}

.skillsAccordion {
  .accordion-item {
    background-color: transparent !important;
  }
}
.activeNavItem {
  background-color: #c5bdbd;
  font-weight: bolder;
}
